import { Link } from '@reach/router';
import { isEmpty } from 'lodash';
import React from 'react';

import { getProfile } from '../services/auth';

export default function Badge({ className, children, persist = false, subscriptions = [], size = "md" }) {
  if (!persist && isEmpty(subscriptions)) {
    return null;
  }

  const style = { height: 'min-content' };
  const authenticatedUser = getProfile();
  const Component = persist && isEmpty(authenticatedUser.subscriptions) ? Link : 'div';

  return (
    <Component to="/app/classy">
      <div style={style} className={`${className} tracking-widest w-max rounded-full flex justify-center items-center bg-yellow-400 text-black font-heading leading-none ${size === 'sm' ? 'py-0.5 px-3 text-sm' : 'py-2 px-4 text-sm'}`}>
        {children || 'classy'}
      </div>
    </Component>
  );
}
